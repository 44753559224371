import dayjs from 'dayjs';
import { originalVariantColors } from '../constants';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { LightTheme as PulseLightTheme, DarkTheme as PulseDarkTheme } from '../web/pulse/tokens';

const LightTheme = {
  danger: pick(PulseLightTheme.danger, ['700', '800', '900']),
  primary: pick(PulseLightTheme.primary, ['700', '800', '900']),
  warning: pick(PulseLightTheme.warning, ['700', '800', '900']),
  secondary: pick(PulseLightTheme.secondary, ['700']),
};

const DarkTheme = {
  danger: pick(PulseDarkTheme.danger, ['700', '800', '900']),
  primary: pick(PulseDarkTheme.primary, ['700', '800', '900']),
  warning: pick(PulseDarkTheme.warning, ['700', '800', '900']),
  secondary: pick(PulseDarkTheme.secondary, ['700']),
};

type Theme = typeof LightTheme;

export const StateToText: { [key: string]: { text: string; color: string } } = {
  ACTIVE: {
    text: 'Test Running',
    color: 'warning',
  },
  INACTIVE: {
    text: 'Not Started',
    color: 'neutral',
  },
  COMPLETED: {
    text: 'Completed',
    color: 'primary',
  },
};

export const TypeToText: { [key: string]: string } = {
  PRICE: 'Price',
  WIDGET: 'Widget',
};

export function daysBetweenDates(date1: string | Date, date2: string | Date): number {
  const start = dayjs(date1);
  const end = dayjs(date2);

  return end.diff(start, 'days');
}

function getRandomColor(theme: Theme) {
  const categories = Object.keys(theme) as (keyof Theme)[];
  const randomCategory = categories[Math.floor(Math.random() * categories.length)];
  const shades = Object.keys(theme[randomCategory]) as any[];
  const randomShade = shades[Math.floor(Math.random() * shades.length)];
  return { category: randomCategory, shade: randomShade };
}

export function pickRandomColor() {
  const lightColorInfo = getRandomColor(LightTheme);
  const darkColor = DarkTheme[lightColorInfo.category][lightColorInfo.shade];
  const lightColor = LightTheme[lightColorInfo.category][lightColorInfo.shade];
  const colors = { light: lightColor, dark: darkColor };

  if (isEqual(colors, originalVariantColors)) {
    return pickRandomColor();
  }

  return colors;
}

export function getPercentColumnStyleAndText(percentage: number) {
  const style = {
    'text-success-700 bg-success-100': percentage > 0,
    'text-danger-700': percentage < 0,
  };

  const text = percentage > 0 ? `+${percentage}%` : `${percentage}%`;

  return { style, text };
}

export function formatDate(date: Date | string) {
  return dayjs(date).format('MMM D');
}

export function getDatesFromStartDate(startDate: Date | string) {
  const dates: string[] = [];
  let currentDate = dayjs(startDate).startOf('day');
  const now = dayjs().startOf('day');

  while (currentDate.isBefore(now) || currentDate.isSame(now, 'day')) {
    dates.push(formatDate(currentDate.toDate()));
    currentDate = currentDate.add(1, 'day').startOf('day');
  }

  return dates;
}
