import dayjs from 'dayjs';
import calender from 'dayjs/plugin/calendar';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(calender);

export const StateToText: { [key: string]: string } = {
  APPROVED: 'Approved',
  ARCHIVED: 'Archived',
  CLOSED: 'Closed',
  COMMUNICATING: 'Communicating',
  DENIED: 'Denied',
  DISMISSED: 'Dismissed',
  NEW: 'New',
  OPEN: 'Open',
  RESOLVED: 'Resolved',
  RESOLVED_BY_BRAND: 'Resolved by Brand',
  TEMPORARILY_DISMISSED: 'Temporarily Dismissed',
  WAITING_FOR_BRAND: 'Waiting for Brand',
  WAITING_FOR_CUSTOMER: 'Waiting for Customer',
  WAITING_FOR_OP: 'Waiting for OP',
};

export const creditedMap: { [key: string]: boolean } = {
  CARRIER_DID_NOT_RECEIVE: false,
  DAMAGED: true,
  DEFECTIVE_ITEM: false,
  LATE_SHIPPING: false,
  LOST: true,
  MISSING_ITEM: false,
  ORDER_DELIVERED_NOT_RECEIVED: true,
  TRACKING_NOT_MOVING: true,
  PRODUCT_NOT_AS_DESCRIBED: false,
  RETURNED_TO_SENDER: false,
  STOLEN: true,
  WRONG_ADDRESS_CARRIER: true,
  WRONG_ADDRESS_CUSTOMER: false,
  WRONG_ITEM: true,
};

export const ClaimTypeToText: { [key: string]: string } = {
  CARRIER_DID_NOT_RECEIVE: 'Carrier Did Not Receive',
  DAMAGED: 'Damaged',
  DEFECTIVE_ITEM: 'Defective Item',
  LATE_SHIPPING: 'Late Shipping',
  LOST: 'Lost',
  MISSING_ITEM: 'Missing Item',
  ORDER_DELIVERED_NOT_RECEIVED: 'Order Delivered Not Received',
  PRODUCT_NOT_AS_DESCRIBED: 'Product Not As Described',
  RETURNED_TO_SENDER: 'Returned to Sender',
  TRACKING_NOT_MOVING: 'Tracking Not Moving',
  STOLEN: 'Stolen',
  WRONG_ADDRESS_CARRIER: 'Wrong Address Carrier',
  WRONG_ADDRESS_CUSTOMER: 'Wrong Address Customer',
  WRONG_ITEM: 'Wrong Item',
  MULTIPLE: 'Multiple',
};

export const FulfillmentStatusToText: { [key: string]: string } = {
  AWAITING_SHIPMENT: 'Awaiting Shipment',
  DELIVERED: 'Delivered',
  IN_TRANSIT: 'In Transit',
  FULFILLED: 'Fulfilled',
  ON_HOLD: 'On Hold',
  PARTIALLY_FULFILLED: 'Partially Fulfilled',
  OUT_FOR_DELIVERY: 'Out For Delivery',
  ATTEMPTED_DELIVERY: 'Attempted Delivery',
  LABEL_PURCHASED: 'Label Purchased',
  LABEL_PRINTED: 'Label Printed',
  READY_FOR_PICKUP: 'Ready For Pickup',
  CONFIRMED: 'Confirmed',
  SCHEDULED: 'Scheduled',
  UNFULFILLED: 'Unfulfilled',
};

export const ResolutionTypeToText: { [key: string]: string } = {
  ADDRESS_TYPO: 'Customer Shipping Address Typo',
  CUSTOM: 'Custom',
  CUSTOMER_RESPONDED: 'Customer Responded',
  EXPIRED30: 'Claim Timeframe Expired',
  FOLLOW_UP: 'Follow up',
  INSUFFICIENT_SHIPPER: 'Insufficient Shipper Packaging',
  NOT_COVERED: 'Claim Type Not Covered',
  NO_RESPONSE: 'No Response',
  OTHER: 'Other',
  PACKAGE_ARRIVED: 'Package Arrived',
  PHOTO_VERIFIED: 'Delivery Photo Verified',
  REFUND: 'Refund',
  RESHIP: 'Reship',
  STORE_CREDIT: 'Store Credit',
  RETURN_TO_SENDER: 'Return To Sender',
  THRESHOLD_REACHED: 'Excessive Claim Threshold Reached',
  ALREADY_REFUNDED: 'Already Refunded',
  ALREADY_REPLACED: 'Already Replaced',
};

export const StoreCreditToText: { [key: string]: string } = {
  STORE_CREDIT: 'Store Credit',
  GIFT_CARD: 'Gift Card',
  DISCOUNT_CODE: 'Discount Code',
};

export function formatPrice(amount: number, currency: string) {
  const formatter = new Intl.NumberFormat(undefined, {
    currency,
    style: 'currency',
  });

  return formatter.format(amount);
}

export function formatPriceCurrency(amount: number, currency: string) {
  const formatter = new Intl.NumberFormat('en-US', {
    currency,
    style: 'currency',
  });

  return formatter.format(amount);
}

export function getCurrencySymbol(currency: string) {
  const number = new Intl.NumberFormat('en-US', {
    currency,
    style: 'currency',
  }).formatToParts(1);

  const currencyPart = number.find(part => part.type === 'currency');
  return currencyPart ? currencyPart.value : '$';
}

export function getProtectionStatus(order: any) {
  const eligibleForOta =
    (order.policyId && order.eligibleForOta === 'IS_OTA') || (!order.policyId && order.eligibleForOta === 'ELIGIBLE');

  const statusMap: Record<'ota' | 'protected' | 'removed' | 'unprotected', boolean> = {
    ota: eligibleForOta && !order.sourceCancelledAt,
    protected: !!order.policyId && !order.sourceCancelledAt,
    removed: !!order.sourceCancelledAt || !!order.policyCancelledAt,
    unprotected: !order.policyId && !order.sourceCancelledAt && !order.policyCancelledAt,
  };

  for (const status in statusMap) {
    if (statusMap[status as keyof typeof statusMap]) {
      return status;
    }
  }

  return null;
}

export const formatDate = (date: string | Date, timezone?: string): string => {
  const inputDate = timezone ? dayjs.tz(date, timezone) : dayjs(date);

  return inputDate.calendar(null, {
    lastWeek: 'MMM D [at] h:mm A',
    sameElse: 'MMM D [at] h:mm A',
  });
};

export const formatDate2 = (date: string | Date, format = 'DD/MM/YYYY h:m A') => {
  return dayjs(date).format(format);
};

export const claimStateMapper = {
  state: (claimState?: string) => {
    const mapper: Record<string, string> = {
      'on-hold': 'open',
      onhold: 'open',
      todo: 'new',
      pending: 'dismissed',
    };

    return mapper[claimState?.toLowerCase() ?? ''] ?? claimState;
  },
  label: (label?: string): string => {
    const mapper: Record<string, string> = {
      new: 'To Do',
      open: 'On Hold',
      dismissed: 'Pending',
    };

    return mapper[label?.toLowerCase() ?? ''] ?? label;
  },
};
